.container {
}
label {
  display: flex;
}
// ThemeToggle.sass
.ThemeToggle {
  appearance: none;
  width: 62px;
  height: 32px;
  font-size: 0;
  line-height: 0;
  display: inline-block;
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  outline: none;
  border: none;
  cursor: pointer;
  background: var(--color-background-secondary);
  transition: background-color ease 0.3s;

  &::before {
    content: url('../../assets/images/moon.svg');
    display: flex;
    box-sizing: border-box;
    padding: 4px;
    position: absolute;
    z-index: 2;
    width: 24px;
    height: 24px;
    background: var(--color-background);
    left: 4px;
    top: 4px;
    border-radius: 50%;
    line-height: 32px;
    word-spacing: 37px;
    color: #fff;
    white-space: nowrap;
    transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
  }

  &:checked {
    background-color: var(--color-background-secondary);
    border-color: var(--color-background);

    &::before {
      left: 32px;
      content: url('../../assets/images/sun.svg');
      background: var(--color-background);
    }
  }
}
