.container {
}

.containerBox {
  display: flex;
  align-items: center;
  button {
    margin-left: 0.3rem;
    span {
      font-size: 0.9rem;
      margin-right: 0.5rem;
      font-weight: 400;
    }
  }
}

.hidden {
  border-radius: var(--border-radius-s);
  box-shadow: var(--shadow-notification);
  background-color: var(--color-input-bg);
  overflow: hidden;
  min-width: 145px;
  button {
    width: 100%;
    border-radius: 0;
    &:hover {
      background-color: var(--tabs-bg);
    }
  }
  button span {
    font-size: 0.9rem;
    padding: 0.3rem 1.5rem;
    text-align: right;
  }
}
