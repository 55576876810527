.notification {
  box-sizing: border-box;
  padding: 0.8rem 1rem;
  //border: 1px solid var(--color-border-input);
  border-radius: var(--border-radius-s);
  box-shadow: var(--shadow-notification);
  display: flex;
  justify-content: space-between;
  background: var(--notification-bg);
  color: var(--color-text);
  line-height: 1.25rem;
  font-size: 0.875rem;
  min-width: 300px;
}

.title {
  font-weight: 500;
}
.subtitle {
  margin-top: 0.2rem;
  color: var(--color-grey);
}

.icon {
  margin-right: 1rem;
}
.content {
  flex-grow: 1;
}
.action {
  margin-top: 1rem;
  display: flex;
}
.close {
  margin-left: 1rem;
  display: flex;
  justify-content: flex-start;
}
