.hero {
  box-sizing: border-box;
  padding: 5rem 1rem;
  background-image: url('../../assets/images/bg-3.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  h1 {
    font-size: 4.5rem;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    //color: rgb(15, 23, 42);
    span {
      color: var(--color-primary);
    }
  }
  p {
    color: var(--color-secondary);
    margin-top: 2rem;
    line-height: 1.6;
    font-size: 1.125rem;
    text-align: center;
  }
}

.form {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 2rem;
  p {
    text-align: center;
    margin-top: 0.5rem;
    font-size: 0.825rem;
    color: var(--color-secondary);
  }
  form {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      margin-left: 0.5rem;
    }
  }
}

.launch {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  span {
    display: inline-block;
    box-sizing: border-box;
    padding: 0.1rem 0.75rem;
    background: var(--color-background-secondary);
    font-size: 0.8rem;
    letter-spacing: 0.2px;
    font-weight: 500;
    border-radius: var(--border-radius-sm);
  }
}

.advantages {
  margin-top: 5rem;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
  }
  h2 + p {
    color: var(--color-secondary);
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
}
.advantagesList {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
}
.advantagesCenter {
  width: 26%;
  min-width: 26%;
  margin: 0 2rem;
  img {
    width: 100%;
    height: auto;
    position: sticky;
    top: 20px;
  }
}
.advantagesRight,
.advantagesLeft {
  width: 37%;
}
.advantageItem {
  box-sizing: border-box;
  padding: 1.2rem;
  margin: 1rem 0;
  border-radius: var(--border-radius-s);
  background-color: var(--color-advantage-item);
  width: 100%;
  align-self: flex-start;
  span {
    font-weight: 600;
    line-height: 1.4rem;
    display: block;
  }
  p {
    color: var(--color-secondary);
    margin-top: 0.3rem;
    line-height: 1.4rem;
  }
}

.chart {
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
  }
  h2 + p {
    color: var(--color-secondary);
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
}
.chartContent {
  max-width: 30%;
  min-width: 30%;
  margin-right: 2rem;
}
.chartContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--color-border-input);
}

.calculator {
  margin-top: 5rem;

  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
  }
  h2 + p {
    color: var(--color-secondary);
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
}
.calculatorContainer {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
}
.calculatorContainerText {
  width: 40%;
  margin-right: 2rem;
  span {
    display: block;
    margin-top: 1.5rem;
    font-size: 0.755rem;
    color: var(--color-secondary);
    line-height: 1.2rem;
  }
}
.calculatorContainerForm {
  box-sizing: border-box;
  padding: 1.2rem;
  width: 450px;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--color-border-input);
  span {
    padding-top: 0;
  }
}

.duration {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}
.durationActions {
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin: 0 0.4rem;
    text-align: center;
    background: var(--color-border-input);
    color: var(--color-text);
    cursor: pointer;
  }
}

button.selectedDay {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.calculatorTotal {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 1rem;
    color: var(--color-secondary);
  }
  p {
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--color-primary);
  }
  button {
    span {
      font-size: 1rem !important;
      color: var(--color-white) !important;
    }
  }
}

.faq {
  margin-top: 5rem;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
  }
  h2 + p {
    color: var(--color-secondary);
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
}

.faqList {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.faqItem {
  width: 30%;
  margin-bottom: 2rem;
  span {
    font-weight: bold;
  }
  p {
    color: var(--color-secondary);
    margin-top: 0.3rem;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 750px) {
  .advantagesList {
    display: block;
  }
  .advantagesLeft {
    width: 100%;
  }
  .advantagesCenter {
    width: 100%;
    text-align: center;
    margin-left: 0;
    img {
      max-width: 300px;
      margin: 0 auto;
    }
  }
  .advantagesRight {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .hero {
    padding: 3rem 1rem;
  }
  .advantages {
    margin-top: 1rem;
    h2 {
      font-size: 1.6rem;
      font-weight: 600;
    }
    h2 + p {
      color: var(--color-secondary);
      margin-top: 1rem;
      max-width: 750px;
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }

  .faq {
    margin-top: 3rem;
    h2 {
      font-size: 1.6rem;
      font-weight: 600;
    }
    h2 + p {
      color: var(--color-secondary);
      margin-top: 1rem;
      max-width: 750px;
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
  .faqItem {
    width: 47%;
  }

  .calculator {
    margin-top: 3rem;

    h2 {
      font-size: 1.6rem;
      font-weight: 600;
    }
    h2 + p {
      color: var(--color-secondary);
      margin-top: 1rem;
      max-width: 750px;
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
  .calculatorContainer {
    display: block;
  }
  .calculatorContainerText {
    width: 100%;
    margin-bottom: 2rem;
  }
  .calculatorContainerForm {
    width: 100%;
  }

  .chart {
    margin-top: 3rem;
    display: block;
    h2 {
      font-size: 1.6rem;
      font-weight: 600;
    }
    h2 + p {
      color: var(--color-secondary);
      margin-top: 1rem;
      max-width: 750px;
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }

  .chartContent {
    width: 100%;
    margin-bottom: 2rem;
    max-width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .hero h1 {
    font-size: 11vw;
  }
  .hero p {
    font-size: 3.3vw;
  }

  .form p {
    font-size: 3vw;
  }

  .advantages h2 {
  }

  .faqItem {
    width: 100%;
  }
}

@media screen and (max-width: 460px) {
  .calculatorContainerForm span {
    font-size: 0.925rem;
  }
  .durationActions button {
    margin: 0 1vw;
    width: 11vw;
    height: 11vw;
  }
  .chartContainer {
    padding: 0;
  }
}

html:lang(de) {
  @media screen and (max-width: 460px) {
    .hero h1 {
      font-size: 9vw;
    }
    .hero p {
      font-size: 3.1vw;
    }
  }
}
