.input {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  display: block;
  border-width: 1px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  padding-bottom: 0.5rem;
  background: var(--color-input-bg);
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--color-border-input);
  color: var(--color-text);
  box-shadow: var(--shadow-input);
}
.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
}
.label {
  color: var(--color-text);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.prefixContainer {
  position: absolute;
  left: 0.7rem;
  top: 0.51rem;
  font-size: 0.875rem;
  color: var(--color-grey);
}

.subContainer {
  position: absolute;
  padding-right: 0.5rem;
  right: 2px;
  top: 2px;
  bottom: 2px;
  border-radius: var(--border-radius-s);
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: var(--color-grey);
  background: var(--color-input-bg);
  span {
    color: var(--color-secondary);
    line-height: 1;
    padding-top: 4px;
  }
  span + * {
    margin-left: 0.5rem;
  }
}

.iconContainer {
  position: absolute;
  left: 0.7rem;
  top: 0.51rem;
  font-size: 0.875rem;
  color: var(--color-grey);
}

.input::placeholder {
  color: var(--color-secondary);
}

.input:disabled {
  background: var(--input-disabled-bg);
  color: var(--color-secondary);
}

.input:focus {
  outline: 2px solid var(--color-primary);
  outline-offset: -1px;
}

.prefix {
  padding-left: 1.5rem;
}

.prefixIcon {
  padding-left: 2.5rem;
}

.stablePrefixInput {
  border-left: none;
  border-radius: 0 var(--border-radius-s) var(--border-radius-s) 0;
}

.stablePrefix {
  border-width: 1px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  padding-bottom: 0.5rem;
  background: var(--color-input-prefix-bg);
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.25rem;
  border-radius: var(--border-radius-s) 0 0 var(--border-radius-s);
  border: 1px solid var(--color-border-input);
  color: var(--color-input-prefix);
  box-shadow: var(--shadow-input);
}
.error {
  border-color: var(--color-red);
}
.full {
  width: 100%;
}
.m {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.prefix-size-l {
  font-size: 1.05rem;

  margin-top: 3px;
}
.s {
  font-size: 0.875rem;
  line-height: 160%;
  padding: 0.68rem 1rem;
}
.l {
  font-size: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.errorField {
  color: var(--color-red);
  font-size: 0.825rem;
  margin-top: 0.1rem;
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
  }
}
