.container {
  max-width: 1200px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: 0 auto;
}

@media screen and (max-width: 540px) {
  .container {
    padding: 0 15px;
  }
}
