@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display Regular'), url(./assets/fonts/SFPRODISPLAYREGULAR.OTF) format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display Medium'), url(./assets/fonts/SFPRODISPLAYMEDIUM.OTF) format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display Bold'), url(./assets/fonts/SFPRODISPLAYBOLD.OTF) format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}
