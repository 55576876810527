.dashboard {
  border-top: 1px solid var(--color-grey);
  box-sizing: border-box;
  padding: 2rem 0;
}

.dashboardContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.aside {
  min-width: 220px;
  width: 220px;
  margin-right: 2rem;
}

.main {
  width: 100%;
  flex-grow: 1;
}
