.navbar {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  justify-content: space-between;
  svg {
    margin-right: 0.5rem;
  }
  li {
    margin-bottom: 0.2rem;
    font-weight: 500;
    font-size: 0.875rem;
    a {
      padding: 0.3rem 0.5rem;
      display: flex;
      align-items: center;
      border-radius: var(--border-radius-s);
      &:hover {
        background-color: var(--color-background-secondary);
      }
    }
    a.active {
      color: red;
    }
  }
}

.subnavigation {
  box-sizing: border-box;
  margin-top: 100%;
  svg {
    margin-right: 0.5rem;
  }
  li {
    margin-bottom: 0.2rem;
    font-weight: 500;
    font-size: 0.875rem;
    a {
      padding: 0.3rem 0.5rem;
      display: flex;
      align-items: center;
      border-radius: var(--border-radius-s);
    }
  }
}
