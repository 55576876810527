.button {
  cursor: pointer;
  font-weight: 500;
  color: var(--color-black);
  border-color: transparent;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  border-width: 1px;
  border-radius: var(--border-radius-s);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  border-style: solid;
  white-space: nowrap;
  padding-bottom: 0.5rem;
  transition: background-color 0.3s ease-in-out;
  //box-shadow: var(--shadow-button);
  &:hover {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
}
.button:focus {
  outline: 2px solid var(--color-primary);
  outline-offset: 2px;
}
.button span {
  font-size: 0.8125rem;
  font-weight: 500;
}
.button span + * {
  margin-left: 0.5rem;
}

.leftIcon svg {
  order: -1;
}
.leftIcon span + * {
  margin-left: 0;
  margin-right: 0.5rem;
}
.button:active {
  outline: 2px solid var(--color-primary);
  outline-offset: 2px;
}

.xs {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  font-size: 0.7125rem;
}

.s {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  font-size: 0.8125rem;
}
.md {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.8125rem;
}
.l {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  font-size: 1rem;
}
.xl {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  font-size: 1.25rem;
}
.primary {
  background: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-white);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.secondary {
  background: var(--color-bg-button-secondary);
  color: var(--color-text);
  border-color: #e5e6e8;
}
.error {
  background: var(--color-red);
  color: var(--color-white);
  border-color: var(--color-red);
}

.success {
  background: var(--color-success);
  color: var(--color-white);
  border-color: var(--color-success);
}

.unstyled {
  padding: 0;
  margin: 0;
  align-self: flex-start;
  box-shadow: none;
  color: var(--color-text);
  svg {
    margin: 0 !important;
  }
}
